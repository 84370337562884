@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Benzin";
  src: url("/src/assets/Benzin-Bold\ .ttf") format("truetype");
}

html::-webkit-scrollbar,
.remove-scroll::-webkit-scrollbar {
  width: 0rem;
  display: none;
}

html,
.remove-scroll {
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0;
  font-family: "Montserrat", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-color: #c1c1c1; */
}

.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  color: black !important;
  border-radius: 50%;
  padding: 0.4rem 0.4em 0.4rem 0.4rem;
  height: 3rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 3rem !important;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1rem !important;
  font-weight: bold;
  color: black !important;
}
@media (min-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 1.5rem !important;
  }
}

.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  color: black !important;
  border-radius: 50%;
  padding: 0.4rem 0.4em 0.4rem 0.4rem;
  height: 3rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 3rem !important;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1rem !important;
  font-weight: bold;
  color: black !important;
}
@media (min-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 1.5rem !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  .btn-primary {
    @apply bg-app-primary  py-2 px-5  text-white  rounded-xl font-bold cursor-pointer w-full disabled:opacity-80 disabled:cursor-not-allowed;
  }

  .btn-secondary {
    @apply bg-app-secondary  py-2 px-5  text-black  rounded-xl font-bold cursor-pointer w-full disabled:opacity-80 disabled:cursor-not-allowed;
  }
  .btn-danger {
    @apply bg-red-500 py-2 px-5 text-white rounded font-bold;
  }
  .btn-success {
    @apply bg-green-500 py-2 px-5 text-white rounded font-bold;
  }

  .btn-danger {
    @apply bg-red-500 py-2 px-5 text-white rounded font-bold;
  }

  .btn-primary-rounded {
    @apply bg-primary rounded-full p-1 text-white;
  }
}

@layer components {
  .tabs {
    @apply w-full  z-50 font-bold py-5 text-center border-b-4;
  }
  .tabs-active {
    @apply border-secondary text-secondary;
  }
}
